<script lang="ts" setup>
import type { Game } from "@/types";

const { type, title, description } = defineProps<{
	type: "favorite" | "jackpot";
	title: string;
	description?: string;
	withGameOfWeek?: boolean;
}>();

const isGuest = useIsGuest();
const emit = defineEmits<{ (event: "update"): void }>();

const { t } = useT();
const { games, gamesWithWeekGame, gameofWeek, jackpotGames } = useHomePage();

const gameList = computed(() => ({
	favorite: gamesWithWeekGame.value?.filter((game) => game.id !== gameofWeek?.value?.id) || [],
	jackpot: jackpotGames.value?.filter((game) => game.id !== gameofWeek?.value?.id) || []
}));

const routes = {
	favorite: "/issues/all-games/",
	jackpot: "/issues/jackpot/"
} as const;

const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { handleToggleToFavoriteClick } = useAddToFavorite();

const gameOfTheWeek = computed(() => {
	const gameOfTheWeek = games.value?.find((game) => game.id === gameofWeek.value?.id);

	if (!gameOfTheWeek) {
		return null;
	}

	const img =
		(gameofWeek.value as { image2x?: string; image?: string })?.image2x ||
		(gameofWeek.value as { image2x?: string; image?: string })?.image;

	return {
		...gameOfTheWeek,
		img
	};
});
const { recentWinners } = useLatestWinners();
const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-${type}-first-item`;
	}
	return null;
};

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const handleFavoriteClick = (game: Game) => {
	handleToggleToFavoriteClick(game || {});
	emit("update");
};
</script>

<template>
	<div class="games">
		<div class="games-header mb-4">
			<div class="games-header__left">
				<AText type="h8 h5-md" data-tid="slider-title-top-games" class="games-header__title">{{ title }}</AText>
				<AText v-if="description" type="small base-md" class="games-header__sub-title">{{ description }}</AText>
			</div>
		</div>
		<div class="games-list my-4">
			<MGame
				v-if="withGameOfWeek"
				class="games-list__item games-list__item--week-game"
				:is-guest="isGuest"
				:game="gameOfTheWeek"
				:image="`${baseImageUrl}${gameOfTheWeek?.img}`"
				:image-pattern="imagePattern"
				is-game-of-week
				:winner-data="recentWinners.get(gameOfTheWeek?.id ?? 0)"
				skipGamePreview
				@toggle-favorite="handleFavoriteClick(gameOfTheWeek as Game)"
				@play="handleOpenGame(gameOfTheWeek?.slug, 'open_game')"
			/>

			<MGame
				v-for="(game, index) in gameList[type]"
				:key="game.id"
				:class="['games-list__item', { 'week-game': withGameOfWeek }]"
				:is-guest="isGuest"
				:game="game"
				:image="`${baseImageUrl}${game.logo}`"
				:image-pattern="imagePattern"
				:winner-data="recentWinners.get(game.id ?? 0)"
				:data-tid="getDataTid(index)"
				skipGamePreview
				@toggle-favorite="handleFavoriteClick(game)"
				@play="handleOpenGame(game?.slug, 'open_game')"
			/>
		</div>
		<div class="d-flex justify-content-center">
			<AButton
				variant="primary"
				size="lg"
				:data-tid="`slider-${getTitle(title)}-all`"
				class="view-all"
				@click="navigateTo(routes[type])"
				>{{ t("View all") }}</AButton
			>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.games {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__left {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		&__title {
			color: var(--neutral);
		}

		&__sub-title {
			color: var(--gray-300);
		}

		&__link {
			width: 100%;
			max-width: 240px;
		}
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 8px;
		position: relative;

		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(4, 1fr);
		}

		@include media-breakpoint-down(sm) {
			grid-template-columns: repeat(3, 1fr);
		}

		&:after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 70px;
			display: block;
			content: "";
			background: linear-gradient(0deg, #161616 0%, rgba(22, 22, 22, 0.89) 33.5%, rgba(22, 22, 22, 0) 100%);
		}

		&__item {
			width: 100% !important;

			&:nth-child(n + 18) {
				display: none;
			}

			@include media-breakpoint-down(md) {
				&:nth-child(n + 13):not(.week-game) {
					display: none;
				}
			}

			@include media-breakpoint-down(sm) {
				&:nth-child(n + 14) {
					display: none;
				}
			}

			&--week-game {
				width: auto !important;
				grid-column: span 2;
				position: relative;

				@include media-breakpoint-down(sm) {
					grid-column: span 3;
				}

				&:deep(.badge-wrap)::before {
					content: "game of the week";
					background: var(--gradient-10);
					border-radius: 16px;
					padding: 2px 8px;
					font-weight: 700;
					font-size: 10px;
					text-transform: uppercase;
					color: var(--neutral);
					font-family: var(--font-family-secondary);
					white-space: nowrap;
				}
			}
		}
	}

	.view-all {
		width: 100%;
		max-width: 240px;
	}
}
</style>
